.ContentDetailTitle {
  .content-detail-category {
    margin-right: 0.25rem;
    border: 1px solid #eee;
    border-radius: 12px;
    padding: 0.25rem;
    min-width: 60px;
    text-align: center;
    color: #fff;
    font-size: 0.75rem;
  }

  .fall-prevention {
    background: #ff8a70;
  }

  .mental-health {
    background: #6993db;
  }

  .rehabilition {
    background: #5ac569;
  }
}
