.HomeContentList {
  .home-content-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5rem;
  }
  .home-content-pagination {
    .pagination {
      display: block;
      margin: 0.5rem 0 0 0;
      text-align: center;

      li {
        display: inline-block;
        font-size: 16px;
        font-weight: 400;
        width: 16px;
        line-height: 30px;
        margin-right: 5px;
        margin-left: 5px;
        border: none;
        cursor: pointer;
        -ms-user-select: none;
        -moz-user-select: -moz-none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        outline: none;

        a {
          outline: none;
          display: block;
        }

        &:hover {
          transition: all 0.2s linear;
          color: #6bce7c;
        }
      }

      .rc-pagination-jump-prev:after,
      .rc-pagination-jump-next:after {
        content: '\2022\2022\2022';
        display: block;
        letter-spacing: 2px;
        color: #555555;
        font-size: 16px;
        margin-top: 0px;
      }

      .rc-pagination-jump-prev:after,
      .rc-pagination-jump-next:after {
        content: '\2022\2022\2022';
        display: block;
        letter-spacing: 2px;
        color: #555555;
        font-size: 16px;
        margin-top: 0px;
      }

      .rc-pagination-jump-next:hover:after {
        color: #ffffff;
        font-size: 16px;
        content: '\BB';
      }

      .rc-pagination-jump-prev:hover:after {
        color: #ffffff;
        font-size: 16px;
        content: '\AB';
      }

      .rc-pagination-item-active {
        transition: all 0.2s linear;
        color: #6bce7c;
      }
      .rc-pagination-next {
        color: #6bce7c;
        font-size: 1.25rem;
        font-weight: 500;
      }
      .rc-pagination-prev {
        color: #6bce7c;
        font-size: 1.25rem;
        font-weight: 500;
      }
    }
  }
}
