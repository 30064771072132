.ContentDetailVideo {
  margin: 1rem 0;
  .content-detail-video {
    padding: 1rem 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    iframe {
      width: 100%;
      height: 520px;
      border: none;
    }
  }
}
