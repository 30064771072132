.GradingReportBox {
  position: relative;

  .grading-report-btn {
    position: absolute;
    top: -3.2rem;
    right: 0;
    background: #6bce7c;
    padding: 1rem 1rem 2rem 1rem;
    border-radius: 12px;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    cursor: pointer;
  }

  .grading-report-box-content {
    position: relative;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 1rem;
  }
}
