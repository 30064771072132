.CloudBackground {
  background-image: url(/images/bg.png);
  background-repeat: repeat-x;
  background-position: center;
  background-size: contain;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 64%;
}

@media all and (max-width: 1200px) {
  .CloudBackground {
    background-position: top;
  }
}
