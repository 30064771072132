.Footer {
  padding: 1rem;
  .footer-wrap {
    display: flex;
    margin: 1rem 0;

    .footer-left {
      flex: 1 1;
      margin-right: 1rem;
      p {
        margin: 0.2rem 0;
        font-size: 0.8rem;
        word-break: keep-all;
      }
    }

    .footer-right {
      select {
        padding: 0.25rem;
        font-size: 0.8rem;
        outline: none;
        cursor: pointer;
      }
    }
  }
}
