.ReportBtnItem {
  display: inline-block;
  width: calc(33.3% - 1rem);
  text-align: center;
  margin: 0 0.5rem;
  padding: 1rem;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: 500;
  border: none;
  outline: none;
  cursor: pointer;
}

.fall-active {
  background: #ff8a70;
  color: #fff;
}

.mental-active {
  background: #6993db;
  color: #fff;
}

.rehabilitaion-active {
  background: #5ac569;
  color: #fff;
}
