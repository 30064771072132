.HomeContentItem {
  width: calc(25% - 1rem);
  margin: 0.5rem 0.5rem;
  text-decoration: none;
  color: #333;

  cursor: pointer;
  .home-content-top {
    position: relative;
    display: inline-block;

    .home-content-category {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      border: 1px solid #eee;
      border-radius: 12px;
      padding: 0.25rem;
      min-width: 60px;
      text-align: center;
      color: #fff;
      font-size: 0.75rem;
    }

    .fall-prevention {
      background: #ff8a70;
    }

    .mental-health {
      background: #6993db;
    }

    .rehabilition {
      background: #5ac569;
    }

    .home-content-thumbnail {
      width: 100%;
      height: 150px;
      vertical-align: bottom;
    }
  }
  .home-content-bottom {
    border: 1px solid #eee;
    padding: 0.25rem;
    .home-content-name {
      text-align: left;
      margin: 0 0.25rem;
      font-size: 0.85rem;
      font-weight: 500;
      padding: 0.15rem 0;
    }
  }
}
